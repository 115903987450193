import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { Column, DataAlign } from 'destiny/dist/types/table';
import AccountBalance from 'modules/accounts-mea/components/AccountBalance';
import ChevronUpDownToggle from 'modules/accounts-mea/components/ChevronUpDownToggle';
import LogoLabelInfo from 'modules/accounts-mea/components/LogoLabelInfo';
import AccountHolderName from 'modules/accounts-mea/views/accountDetails/treasuryAccountDetails/linkedAccounts/AccountHolderName';
import AccountNumberDetails from 'modules/accounts-mea/views/accountDetails/treasuryAccountDetails/linkedAccounts/AccountNumberDetails';
import EntityCollapseAll from 'modules/accounts-mea/views/entityListing/components/entityHeader/EntityCollapseAll';
import SMEntityAccountBalance from 'modules/accounts-mea/views/entityListing/components/entityTitle/SMEntityAccountBalance';
import SMEntityTitleLabel from 'modules/accounts-mea/views/entityListing/components/entityTitle/SMEntityTitleLabel';
import { MapAny } from 'types';
import { ACCESS_SCOPES } from 'types/access';
import { PaymentMethod } from 'types/transactions';
import SkeletonElement from 'components/skeletons/SkeletonElement';

export const CUSTOMER_PROFILE = {
  SMALL_MERCHANT: 'smb',
  LARGE_MERCHANT: 'enterprise',
};

export enum ACCOUNTS_PAGE_VIEWS {
  ENTITY_LISTING = 'ENTITY_LISTING',
  VAULT_DETAILS = 'VAULT_DETAILS',
  BANKING_ACCOUNT_DETAILS = 'BANKING_ACCOUNT_DETAILS',
  TREASURY_ACCOUNT_DETAILS = 'TREASURY_ACCOUNT_DETAILS',
}

export const ACCOUNT_TYPE = {
  BROKERAGE_CASH_ACCOUNT: 'brokerage_cash_account',
  PAYOUTS_CASH_ACCOUNT: 'payouts_cash_account',
};

export enum ACCOUNT_BALANCE_KEYS {
  TOTAL = 'totalBalance',
  TREASURY = 'treasuryAccountsBalance',
  BANKING = 'bankingAccountsBalance',
  DIGITAL_ASSET = 'digitalAssetsAccountsBalance',
}

export const ACCOUNT_FILTER_TYPE = {
  TOTAL: 'total',
  TREASURY: 'treasury',
  BANKING: 'banking',
  DIGITAL_ASSET: 'digital_asset',
};

export const ACCOUNT_FILTER_TYPE_BALANCE_KEY_MAP = {
  [ACCOUNT_FILTER_TYPE.TOTAL]: {
    key: ACCOUNT_BALANCE_KEYS.TOTAL,
  },
  [ACCOUNT_FILTER_TYPE.TREASURY]: {
    key: ACCOUNT_BALANCE_KEYS.TREASURY,
  },
  [ACCOUNT_FILTER_TYPE.BANKING]: {
    key: ACCOUNT_BALANCE_KEYS.BANKING,
  },
  [ACCOUNT_FILTER_TYPE.DIGITAL_ASSET]: {
    key: ACCOUNT_BALANCE_KEYS.DIGITAL_ASSET,
  },
};

export const ACCOUNT_FILTER_TYPE_BALANCE_MAP = {
  [ACCOUNT_BALANCE_KEYS.TOTAL]: {
    title: 'Total balance',
    key: ACCOUNT_BALANCE_KEYS.TOTAL,
  },
  [ACCOUNT_BALANCE_KEYS.TREASURY]: {
    title: 'Balance in treasury accounts',
    key: ACCOUNT_BALANCE_KEYS.TREASURY,
  },
  [ACCOUNT_BALANCE_KEYS.BANKING]: {
    title: 'Balance in banking accounts',
    key: ACCOUNT_BALANCE_KEYS.BANKING,
  },
  [ACCOUNT_BALANCE_KEYS.DIGITAL_ASSET]: {
    title: 'Balance in digital assets accounts',
    key: ACCOUNT_BALANCE_KEYS.DIGITAL_ASSET,
  },
};

const CHEVRON_TOGGLE_ICON_COLUMN = {
  id: 'icon',
  className: 'tw-w-6',
  Component: ChevronUpDownToggle,
  componentProps: {},
};

const CHEVRON_TOGGLE_ICON_PLACEHOLDER_COLUMN = { id: 'icon', className: 'tw-w-6' };

const CHEVRON_ACTION_ICON_COLUMN = {
  id: 'action',
  Component: () => (
    <SvgSpriteLoader id='chevron-right' iconCategory={ICON_SPRITE_TYPES.ARROWS} width={24} height={24} />
  ),
  displayOnRowHover: true,
  className: 'tw-w-[72px]',
};

const CHEVRON_ACTION_ICON_COLUMN_PLACEHOLDER = {
  id: 'action',
  className: 'tw-w-[72px]',
};

export const ACCORDIAN_TITLE_SKELETON_COLUMNS: Column[] = [
  { id: 'icon', Component: () => <SkeletonElement className='tw-w-6 tw-h-6' />, className: 'tw-w-6' },
  {
    id: 'skeleton_1',
    Component: () => <SkeletonElement className='tw-w-[180px] tw-h-4.5' />,
    className: 'tw-w-[calc(39.34%-32px)]',
  },
  {
    id: 'skeleton_2',
    Component: () => <SkeletonElement className='tw-w-[180px] tw-h-4.5' />,
    className: 'tw-w-[calc(30.33%-32px)]',
  },
  {
    id: 'skeleton_3',
    className: 'tw-w-[calc(30.33%-32px)]',
  },
  {
    id: 'skeleton_4',
    className: 'tw-w-[72px]',
  },
];

export const LISTING_SKELETON_COLUMNS: Column[] = [
  {
    ...CHEVRON_TOGGLE_ICON_PLACEHOLDER_COLUMN,
  },
  {
    id: 'skeleton_1',
    Component: LogoLabelInfo,
    className: 'tw-w-[calc(39.34%-32px)]',
  },
  {
    id: 'skeleton_2',
    Component: () => (
      <div className='tw-flex tw-flex-col tw-gap-1.5'>
        <SkeletonElement className='tw-w-[180px] tw-h-4.5' />
        <SkeletonElement className='tw-w-[120px] tw-h-[14px]' />
      </div>
    ),
    className: 'tw-w-[calc(30.33%-32px)]',
  },
  {
    id: 'skeleton_3',
    Component: () => <SkeletonElement className='tw-w-[220px] tw-h-4.5' />,
    className: 'tw-w-[calc(30.33%-32px)]',
  },
  {
    id: 'skeleton_4',
    className: 'tw-w-[72px]',
  },
];

export const ENTITY_HEADER__SKELETON_COLUMNS: Column[] = [
  {
    id: 'collapse_all',
    Component: () => <SkeletonElement className='tw-w-6 tw-h-6' />,
    className: 'tw-w-6',
  },
  {
    id: 'account_logo_label_info',
    Component: () => <SkeletonElement className='tw-w-[58px] tw-h-4.5' />,
    className: 'tw-w-[calc(39.34%-32px)]',
  },
  {
    id: 'account_balance',
    Component: () => <SkeletonElement className='tw-w-[50px] tw-h-4.5' />,
    className: 'tw-w-[calc(30.33%-32px)]',
  },
  {
    id: 'bank_network_name',
    Component: () => <SkeletonElement className='tw-w-[88px] tw-h-4.5' />,
    className: 'tw-w-[calc(30.33%-32px)]',
  },
  {
    ...CHEVRON_ACTION_ICON_COLUMN_PLACEHOLDER,
  },
];

export const ENTITY_HEADER_COLUMNS: Column[] = [
  {
    id: 'collapse_all',
    Component: EntityCollapseAll,
    className: 'tw-w-6 tw-cursor-pointer',
  },
  {
    id: 'account_logo_label_info',
    keyNames: ['accounts'],
    className: 'tw-w-[calc(39.34%-32px)] f-10-600 tw-text-TEXT_TERTIARY',
  },
  {
    id: 'account_balance',
    keyNames: ['balance'],
    className: 'tw-w-[calc(30.33%-32px)] f-10-600 tw-text-TEXT_TERTIARY',
  },
  {
    id: 'bank_network_name',
    keyNames: ['bank_network'],
    className: 'tw-w-[calc(30.33%-32px)] f-10-600 tw-text-TEXT_TERTIARY',
  },
  {
    ...CHEVRON_ACTION_ICON_COLUMN_PLACEHOLDER,
  },
];

export const ENTITY_HEADER_COLUMNS_OTC: Column[] = [
  {
    id: 'collapse_all',
    Component: EntityCollapseAll,
    className: 'tw-w-6 tw-cursor-pointer first:tw-pl-4',
  },
  {
    id: 'account_logo_label_info',
    keyNames: ['accounts'],
    className: 'tw-w-[calc(39.34%-32px)] f-10-600 tw-text-TEXT_SECONDARY',
  },
  {
    id: 'account_balance',
    keyNames: ['balance'],
    className: 'tw-w-[calc(30.33%-32px)] f-10-600 tw-text-TEXT_SECONDARY',
  },
  {
    id: 'bank_network_name',
    keyNames: ['bank_network'],
    className: 'tw-w-[calc(30.33%-32px)] f-10-600 tw-text-TEXT_SECONDARY',
  },
  {
    ...CHEVRON_ACTION_ICON_COLUMN_PLACEHOLDER,
  },
];

export const LARGE_MERCHANT_ENTITY_TITLE_COLUMNS: Column[] = [
  {
    ...CHEVRON_TOGGLE_ICON_COLUMN,
  },
  {
    id: 'logo_label_info',
    keyNames: ['name', 'currency_code', 'balance_currency_code'],
    className: 'tw-w-[calc(50%-48px)]',
    Component: LogoLabelInfo,
    componentProps: {},
  },
  {
    id: 'card',
    keyNames: ['balance', 'currency_code', 'balance_currency_code'],
    className: 'tw-text-TEXT_PRIMARY tw-w-[calc(50%-48px)]',
    align: DataAlign.LEFT,
    Component: AccountBalance,
    componentProps: { checkBalanceValidity: true },
  },
  {
    id: 'action',
    className: 'tw-w-[72px]',
  },
];

export const SMALL_MERCHANT_ENTITY_TITLE_COLUMNS: Column[] = [
  {
    ...CHEVRON_TOGGLE_ICON_COLUMN,
  },
  {
    id: 'label_info',
    keyNames: ['name', 'balance', 'balance_currency_code'],
    Component: SMEntityTitleLabel,
    componentProps: {},
  },
];

export const SMALL_MERCHANT_ENTITY_TITLE_COLUMNS_V2: Column[] = [
  {
    ...CHEVRON_TOGGLE_ICON_COLUMN,
  },
  {
    id: 'label_info',
    keyNames: ['name'],
    className: 'tw-w-[calc(39.34%-32px)] f-13-500 tw-uppercase tw-text-TEXT_PRIMARY',
  },
  {
    id: 'balance',
    keyNames: ['balance', 'balance_currency_code', 'filtered_balance'],
    Component: SMEntityAccountBalance,
    componentProps: {
      showHeader: false,
      balanceClassName: 'f-14-500 tw-text-TEXT_PRIMARY',
    },
    className: 'tw-w-[calc(30.33%-32px)]',
  },
  {
    id: 'placeholder',
    className: 'tw-w-[calc(30.33%-32px)]',
  },
  {
    ...CHEVRON_ACTION_ICON_COLUMN_PLACEHOLDER,
  },
];

export const VAULTS_BY_ENTITY_ID_COLUMNS: Column[] = [
  {
    ...CHEVRON_TOGGLE_ICON_PLACEHOLDER_COLUMN,
  },
  {
    id: 'vault_logo_label_info',
    keyNames: ['name', 'accounts'],
    Component: LogoLabelInfo,
    componentProps: {},
    className: 'tw-w-[calc(50%-48px)]',
  },
  {
    id: 'vault_balance',
    keyNames: ['balance', 'currency_code', 'balance_currency_code'],
    Component: AccountBalance,
    componentProps: {
      showHeader: false,
      wrapperClassName: '',
      balanceClassName: 'f-14-400 tw-text-TEXT_PRIMARY',
    },
    className: 'tw-w-[calc(50%-48px)] f-14-400 tw-text-TEXT_PRIMARY',
  },
  {
    ...CHEVRON_ACTION_ICON_COLUMN,
  },
];

export const ACCOUNTS_BY_ENTITY_ID_COLUMNS: Column[] = [
  {
    ...CHEVRON_TOGGLE_ICON_PLACEHOLDER_COLUMN,
  },
  {
    id: 'account_logo_label_info',
    keyNames: ['name', 'masked_number', 'currency_code', 'balance_currency_code', 'type', 'nick_name'],
    Component: LogoLabelInfo,
    componentProps: {},
    className: 'tw-w-[calc(39.34%-32px)]',
  },
  {
    id: 'account_balance',
    keyNames: ['balance', 'currency_code', 'balance_currency_code', 'usd_display_balance'],
    Component: AccountBalance,
    componentProps: {
      showHeader: false,
      wrapperClassName: '',
      balanceClassName: 'f-14-400 tw-text-TEXT_PRIMARY',
    },
    className: 'tw-w-[calc(30.33%-32px)] f-14-400 tw-text-TEXT_PRIMARY',
  },
  {
    id: 'bank_network_name',
    keyNames: ['bank_network_name'],
    className: 'tw-w-[calc(30.33%-32px)] f-14-400 tw-text-TEXT_PRIMARY',
  },
  {
    ...CHEVRON_ACTION_ICON_COLUMN,
  },
];

export const VAULT_DETAILS_COLUMNS: Column[] = [
  {
    id: 'account_logo_label_info',
    label: 'ACCOUNT',
    keyNames: ['name', 'masked_number', 'currency_code', 'balance_currency_code', 'type', 'nick_name'],
    Component: LogoLabelInfo,
    componentProps: {},
  },
  {
    id: 'account_balance',
    label: 'BALANCE',
    keyNames: ['balance'],
    className: 'f-14-300 tw-text-TEXT_PRIMARY',
    align: DataAlign.RIGHT,
  },
  {
    id: 'currency_code',
    label: 'CURRENCY',
    keyNames: ['currency_code', 'balance_currency_code'],
    Component: AccountBalance,
    componentProps: {
      showHeader: false,
      showCurrencyOnly: true,
      wrapperClassName: '',
      balanceClassName: 'f-14-300 tw-text-TEXT_PRIMARY',
    },
  },
  {
    id: 'bank_network_name',
    label: 'BANK/NEWORK',
    keyNames: ['bank_network_name'],
    className: 'f-14-300 tw-text-TEXT_PRIMARY',
  },
  {
    ...CHEVRON_ACTION_ICON_COLUMN,
  },
];

export const LINKED_ACCOUNTS_TABLE_COLUMNS: Column[] = [
  {
    id: 'name',
    label: 'NAME',
    keyNames: [
      'account_holder_name',
      'account_status',
      'account_linking_type',
      'otp_validation_status',
      'otp_expires_in_seconds',
    ],
    Component: AccountHolderName,
    componentProps: {},
  },
  {
    id: 'bank_name',
    label: 'BANK NAME',
    keyNames: ['bank_name'],
    className: 'tw-text-TEXT_SECONDARY f-14-300',
    componentProps: {},
  },
  {
    id: 'account_number',
    label: 'ACCOUNT NUMBER',
    keyNames: ['otp_validation_status', 'account_linking_type', 'account_number', 'id'],
    Component: AccountNumberDetails,
    componentProps: {},
    align: DataAlign.RIGHT,
  },
];

export const PENDING_ACCOUNTS_TABLE_COLUMNS: Column[] = [
  {
    id: 'account',
    label: 'ACCOUNT',
    keyNames: ['name', 'currency_code', 'type', 'nick_name'],
    Component: LogoLabelInfo,
    componentProps: {},
  },
];

export enum ACCOUNT_ACTIONS {
  SEND_MONEY = 'SEND_MONEY',
  DOWNLOAD_STATEMENT = 'DOWNLOAD_STATEMENT',
  SELF_TRANSFER = 'SELF_TRANSFER',
  ADD_FUNDS = 'ADD_FUNDS',
  WITHDRAW_MONEY = 'WITHDRAW_MONEY',
  CASH_ACCOUNT_SETTINGS = 'CASH_ACCOUNT_SETTINGS',
}

export interface ACCOUNT_ACTION_TYPE {
  iconId: string;
  iconCategory: ICON_SPRITE_TYPES;
  title: string;
  subtitle: string;
  isCustomComponent?: boolean;
  actionName: ACCOUNT_ACTIONS;
  className?: string;
  isDisabled?: boolean;
  permissionId?: string;
  permissionScope?: ACCESS_SCOPES;
}

export const BANKING_ACCOUNT_ACTIONS: ACCOUNT_ACTION_TYPE[] = [
  {
    iconId: 'arrow-narrow-up',
    iconCategory: ICON_SPRITE_TYPES.ARROWS,
    title: 'Send Money',
    subtitle: 'Make real-time payments in 180+ countries',
    actionName: ACCOUNT_ACTIONS.SEND_MONEY,
  },
  // TODO: uncomment once accountId filter is added in Reports page
  // {
  //   iconId: 'file-download-03',
  //   iconCategory: ICON_SPRITE_TYPES.FILES,
  //   title: 'Download  Statement',
  //   subtitle: 'View monthly balances and transactions for this account',
  //   actionName: ACCOUNT_ACTIONS.DOWNLOAD_STATEMENT,
  // },
];

export const TREASURY_ACCOUNT_ACTIONS: ACCOUNT_ACTION_TYPE[] = [
  {
    iconId: 'arrow-narrow-down',
    iconCategory: ICON_SPRITE_TYPES.ARROWS,
    title: 'Add funds',
    subtitle: 'WIRE transfer to cash balance',
    actionName: ACCOUNT_ACTIONS.ADD_FUNDS,
    className: 'tw-basis-2/4',
  },
  {
    iconId: 'arrow-narrow-up',
    iconCategory: ICON_SPRITE_TYPES.ARROWS,
    title: 'Withdraw money',
    subtitle: 'Send your money to your linked accounts',
    actionName: ACCOUNT_ACTIONS.WITHDRAW_MONEY,
    className: 'tw-basis-2/4',
    permissionId: FLOW_PERMISSION_PROPERTIES.MANAGE_TREASURY_WITHDRAWAL.permissionId,
    permissionScope: FLOW_PERMISSION_PROPERTIES.MANAGE_TREASURY_WITHDRAWAL.scope,
  },
  {
    iconId: 'settings-01',
    iconCategory: ICON_SPRITE_TYPES.GENERAL,
    title: '',
    subtitle: '',
    isCustomComponent: true,
    actionName: ACCOUNT_ACTIONS.CASH_ACCOUNT_SETTINGS,
    className: 'tw-basis-full',
    permissionId: FLOW_PERMISSION_PROPERTIES.MANAGE_TREASURY_INVESTMENTS.permissionId,
    permissionScope: FLOW_PERMISSION_PROPERTIES.MANAGE_TREASURY_INVESTMENTS.scope,
  },
];

export const BANKING_COPYABLE_ACCOUNT_DETAILS: any = {
  [PaymentMethod.FIAT]: {
    copyButtonDescription: 'Use these details to add or receive funds in your account',
    copyButtonText: 'Copy Details',
    copyButtonClassName: 'tw-min-w-[130px] tw-max-w-[130px]',
    downloadButtonText: 'Download Details',
    downloadButtonClassName: 'tw-min-w-[160px] tw-max-w-[160px]',
  },
  [PaymentMethod.CRYPTO]: {
    copyButtonDescription: 'Use deposit address to add or receive funds in your account',
    copyButtonText: 'Copy Address',
    copyByButtonOnly: true,
    copyButtonClassName: 'tw-min-w-[138px] tw-max-w-[138px]',
    downloadButtonText: 'Download Address',
    downloadButtonClassName: 'tw-min-w-[164px] tw-max-w-[164px]',
  },
};

export enum TREASURY_ACCOUNT_DETAILS_TAB_ITEMS {
  LINKED_ACCOUNTS = 'LINKED_ACCOUNTS',
  RECENT_TRANSACTIONS = 'RECENT_TRANSACTIONS',
}

export const TREASURY_ACCOUNT_DETAILS_TABS = [
  {
    label: 'Recent Transactions',
    value: TREASURY_ACCOUNT_DETAILS_TAB_ITEMS.RECENT_TRANSACTIONS,
    iconId: 'coins-swap-01',
    iconCategory: ICON_SPRITE_TYPES.FINANCE_AND_ECOMMERCE,
  },
  {
    label: 'Linked Accounts',
    value: TREASURY_ACCOUNT_DETAILS_TAB_ITEMS.LINKED_ACCOUNTS,
    iconId: 'link-03',
    iconCategory: ICON_SPRITE_TYPES.GENERAL,
  },
];

export enum BANKING_ACCOUNT_TYPE {
  INTERNATIONAL = 'INTERNATIONAL',
  LOCAL = 'LOCAL',
}

export const BANKING_ACCOUNT_TYPE_MAP = {
  [BANKING_ACCOUNT_TYPE.INTERNATIONAL]: 'International',
  [BANKING_ACCOUNT_TYPE.LOCAL]: 'Domestic',
};

export interface CopyableAccountDetailsType {
  list: MapAny[];
  tabValue?: string | number;
}

export const ESTIMATED_BALANCE_TOOLTIP = 'This is an approximate value';

export enum CURRENCY_TYPES {
  FIAT = 'FIAT',
  DIGITAL_ASSET = 'DIGITAL_ASSET',
}

export const CURRENCY_TYPES_MAP = {
  [CURRENCY_TYPES.FIAT]: { label: 'Fiat', value: CURRENCY_TYPES.FIAT, key: 'fiat_currencies', id: '' },
  [CURRENCY_TYPES.DIGITAL_ASSET]: {
    label: 'Digital Asset',
    value: CURRENCY_TYPES.DIGITAL_ASSET,
    key: 'digital_assets',
    id: '',
  },
};

export const ADD_ACCOUNT_ALERTS = {
  SUCCESS: 'Account has been created successfully',
  ERROR: 'Failed to create account, Please try again',
};

export enum ACCOUNTS_FILTER_KEYS {
  ENTITIES = 'entities',
  ACCOUNT_TYPES = 'account_types',
  CURRENCIES = 'currencies',
  SORT_TYPES = 'sort_types',
}

export const ACCOUNTS_FILTERS = [
  {
    label: 'Entity',
    iconId: 'building-06',
    iconCategory: ICON_SPRITE_TYPES.GENERAL,
    key: ACCOUNTS_FILTER_KEYS.ENTITIES,
    classNames: {
      className: '!tw-w-[278px]',
    },
  },
  {
    label: 'Account Type',
    iconId: 'list',
    iconCategory: ICON_SPRITE_TYPES.LAYOUT,
    key: ACCOUNTS_FILTER_KEYS.ACCOUNT_TYPES,
    wrapperClassName: 'tw-relative',
  },

  {
    label: 'Currency',
    iconId: 'currency-dollar-circle',
    iconCategory: ICON_SPRITE_TYPES.FINANCE_AND_ECOMMERCE,
    key: ACCOUNTS_FILTER_KEYS.CURRENCIES,
    classNames: {
      className: '!tw-w-[218px]',
      childrenWrapperClassName: 'tw-flex tw-flex-wrap',
      containerClass: 'tw-w-2/4',
    },
  },
  {
    label: 'Sort by',
    iconId: 'switch-vertical-01',
    iconCategory: ICON_SPRITE_TYPES.ARROWS,
    key: ACCOUNTS_FILTER_KEYS.SORT_TYPES,
    wrapperClassName: 'tw-relative',
    classNames: {
      className: '!tw-w-[238px]',
    },
  },
];
export const INITIAL_VALUES = {
  balanceValuesData: {
    totalBalance: 0,
    treasuryAccountsBalance: 0,
    bankingAccountsBalance: 0,
    digitalAssetsAccountsBalance: 0,
  },
  allBalancesData: {
    totalBalance: {},
    treasuryAccountsBalance: {},
    bankingAccountsBalance: {},
    digitalAssetsAccountsBalance: {},
  },
};

export const ZERO_BALANCE_VALUE = '0.00';
export const SwiftDisclaimer =
  "SWIFT deposits from Individuals are not supported due to a disruption with our bank partner's service until further notice. Please do not send in any SWIFT deposits from Individual accounts.";

export const DepositIntructionTitleDescription = {
  [BANKING_ACCOUNT_TYPE.LOCAL]: {
    title: 'Domestic USD Transfer Details',
    description: 'Use these details to receive funds via Domestic Wire or ACH transfers within the US.',
  },
  [BANKING_ACCOUNT_TYPE.INTERNATIONAL]: {
    title: 'International Wire Details',
    description: 'Use these details to receive funds via International Wire or SWIFT from outside the US.',
    notes: [
      { text: 'Please ensure to include a memo when initiating transfers for timely and accurate processing.' },
      {
        text: 'For third party deposits, our partner bank has stricter compliance checks and may request for additional information for processing. (may take up to 3-4 days for processing)',
      },
      {
        text: SwiftDisclaimer,
      },
    ],
  },
};
